import React from "react";
import "./App.css";
import { Container, Segment, Image } from "semantic-ui-react";
import { Route, Switch } from "react-router-dom";
import LandingPage from "./Components/landingPage";
import AddVoucherCode from "./Components/RedeemVoucher/addVoucherCode";
import SelectCredendtials from "./Components/RedeemVoucher/selectCredendtials";
import VerificationSent from "./Components/RedeemVoucher/verificationSent";
import ConfirmAccout from "./Components/RedeemVoucher/confirmAccout";
import SelectEmail from "./Components/ResetPassword/selectEmail";
import SelectNewPassword from "./Components/ResetPassword/selectNewPassword";
import ResetSuccessful from "./Components/ResetPassword/resetSuccess";

function App() {
  return (
    <Container>
    <Container className="disneyglobe-container">
      <Image src="/assets/banner_globe.png" size='massive' centered />
      <div className="li-content-wrapper">
      <div className="globe-container">
      <Switch>
        <Route path="/redeem/:voucherCode/credentials" exact component={SelectCredendtials} />
        <Route path="/redeem" exact component={AddVoucherCode} />
        <Route path="/verificationSent" exact component={VerificationSent} />
        <Route path="/confirmAccount" exact component={ConfirmAccout} />
        <Route path="/resetpassword" exact component={SelectEmail} />
        <Route path="/newpassword/:otp" exact component={SelectNewPassword} />
        <Route path="/resetsuccess" exact component={ResetSuccessful} />
        <Route path="/" component={LandingPage} />
      </Switch>
      </div>
      </div>
    </Container>
    <div className="footer-wrapper"><Image src="/assets/footer.SVG" size='massive' centered /><Image className="palm" src="/assets/footer_palm.png" size='massive' /></div>
      </Container>
  );
}

export default App;
