import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Input, Label, Header, Form } from "semantic-ui-react";
import agent from "../../api/agent";

interface Params {
  otp: string;
}
export default function SelectNewPassword() {
  const { otp } = useParams<Params>();
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [inValidPasswordConfirmation, setInValidPasswordConfirmation] = useState<boolean>(false);
  const [inValidPassword, setInValidPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validatePassword = (): boolean => {
    setInValidPasswordConfirmation(false);
    if (password !== confirmPassword) {
      toast.error("Invalid password confirmation");
      setInValidPasswordConfirmation(true);
      return false;
    }

    //validate password length
    if (password == null || password.trim().length < 6) {
      setInValidPassword(true);
      toast.error("Password should contain at least 6 alphanumeric characters");
      return false;
    } else {
      setInValidPassword(false);
      return true;
    }
  };

  const resetPassword = () => {
    if (!validatePassword()) return;
    setLoading(true);

    agent.Accounts.resetPassword(email, password, otp)
      .then((result) => {
        toast.success("Your Password has been reset successfully");
        history.push("/resetsuccess");
      })
      .catch((error) => {
        toast.error("Password reset failed");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Header as="h1">Enter New Passowrd</Header>
      <Form className="globe-form">
        <Form.Field>
          <Label>Email</Label>
          <Input
            value={email}
            onChange={(e, d) => setEmail(e.target.value)}
            placeholder="Email address"
          />
        </Form.Field>
        <Form.Field>
          <Label>Password</Label>
          <Input
            type="password"
            value={password}
            onChange={(e, d) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </Form.Field>
        <Form.Field>
          <Label>Confirm Password</Label>
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e, d) => setConfirmPassword(e.target.value)}
            placeholder="Confirm password"
          />
          {inValidPasswordConfirmation && <div className="error-msg">Invalid password confirmation</div>}
          {inValidPassword && <div className="error-msg">Password should contain at least 6 alphanumeric characters</div>}
        </Form.Field>
        <Button
          loading={loading}
          positive
          disabled={
            !password || password.trim() === "" || !confirmPassword || confirmPassword.trim() === ""
          }
          onClick={() => {
            resetPassword();
          }}
        >
          Confirm
        </Button>
      </Form>
    </>
  );
}
