import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Segment, Grid, Divider, Image, Header, Icon } from "semantic-ui-react";

export default function LandingPage() {
  const history = useHistory();

  return (
    <>
      <Segment className="logos-segment nostyle">
        <Grid columns={2} stackable textAlign='center'>
          <Divider vertical></Divider>

          <Grid.Row verticalAlign='middle'>
            <Grid.Column>
            <Image src="/assets/LearningIslands.svg" size='small' floated="right" />
            </Grid.Column>

            <Grid.Column>
            <Image src="/assets/globe-logo.png" size='small' floated="left" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <div className="globe-txt">
        <p>Learning Islands featuring <Image className="inline-logo" src="/assets/Disney_Pixar_Logo.svg" size='small' centered /> video stories have teamed up</p><p>with Globe to bring you fun,
        learning and fabulous prizes.</p>
        </div>
        <div className="voucher-code">
        <Button
          positive
          onClick={() => {
            history.push("/redeem");
          }}
        >
          Enter your voucher code
        </Button>
        </div>
    </>
  );
}
