import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Input, Segment, Container, Header } from "semantic-ui-react";
import agent from "../../api/agent";
import { toast } from "react-toastify";

export default function AddVoucherCode() {
  const history = useHistory();
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [invalidCode, setInvalidCode] = useState<boolean>(false);

  const validateCode = () => {
    setInvalidCode(false);
    setLoading(true);
    agent.Vouchers.validate(code)
      .then((result) => {
        if (result === true) history.push(`/redeem/${code}/credentials`);
        else toast.error("Invalid Code");
      })
      .catch(() => {
        setInvalidCode(true);
        toast.error("Invalid Code");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
    <Container className="progressbar-container">
    <ul className="progressbar">
      <li className="active"></li>
      <li></li>
      <li></li>
    </ul>
  </Container>
      <Segment className="nostyle">
        <Header as="h1">14 Day free trial</Header>
        <p>
        Enter the Jungle island and play 4 Disney story videos and 12 activities.
        </p>
      </Segment>
      <Input type="text" value={code} onChange={(e, d) => setCode(e.target.value)}></Input>
      {invalidCode && <div id="divInvalidVoucherCode" className="error-msg">Invalid Voucher Code</div>}
      <Button
      positive
        content="Confirm"
        loading={loading}
        onClick={() => {
          validateCode();
        }}
      ></Button>
    </>
  );
}
