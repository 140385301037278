import { makeAutoObservable } from "mobx";

export default class CommonStore {
    email: string | null = null;
    password: string | null = null;
    voucherCode: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setVoucher = (code: string | null | undefined) => {
        if (code === undefined)
            this.voucherCode = null;
        else
            this.voucherCode = code;
    };

    setEmail = (mail: string | null | undefined) => {
        if (mail === undefined)
            this.email = null;
        else
            this.email = mail;
    };

    setPassword = (pass: string | null | undefined) => {
        if (pass === undefined)
            this.password = null;
        else
            this.password = pass;
    };
}