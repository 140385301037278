import React from "react";
import ReactDOM from "react-dom/client";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { store, StoreContext } from "./stores/store";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
export const history = createBrowserHistory();

root.render(
  <>
    <ToastContainer position="bottom-right" hideProgressBar />
    <StoreContext.Provider value={store}>
      <Router history={history}>
        <App />
      </Router>
    </StoreContext.Provider>
  </>
);
