import React from "react";
import { Header, Icon, Segment, Grid, Image } from "semantic-ui-react";

export default function ConfirmAccout() {
  return (
    <>
      <div><Header className="main-header" as="h1">Success!</Header>
      <Header className="main-header" as="h2"> You account has been created.</Header></div>
      <Icon name="check circle" size="massive" color="green"></Icon>
      <div className="globe-txt"><p>Download the Learning Islands app using the app stor logo below.</p></div>
      <div className="globe-txt"><p>Use the ‘Sign in with Globe’ option to start enjoying Learning Islands.</p></div>
      <Segment className="logos-segment nostyle">
        <Grid columns={2} stackable textAlign='center'>

          <Grid.Row verticalAlign='middle'>
            <Grid.Column>
            <a className="google-btn" href="https://play.google.com/store/apps/details?id=com.lpplus.alpha1_learningislands">
            <Image src="/assets/google-play-badge-Custom.png" size='small' floated="left" />
            </a>
            </Grid.Column>
            <Grid.Column>
            <a className="apple-btn" href="https://apps.apple.com/ph/app/learning-islands/id1574312976">
            <Image src="/assets/app-store-Custom.png" size='small' floated="right" />
            </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}
