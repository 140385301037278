import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Checkbox, Form, Input, Label, Segment, Header, Container } from "semantic-ui-react";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { useStore } from "../../stores/store";

interface Params {
  voucherCode: string;
}

export default function SelectCredendtials() {
  const history = useHistory();
  const { voucherCode } = useParams<Params>();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [age, setAge] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [inValidPasswordConfirmation, setInValidPasswordConfirmation] = useState<boolean>(false);
  const [inValidPassword, setInValidPassword] = useState<boolean>(false);
  const { commonStore } = useStore();

  const sendOTP = () => {
    if (!validateEmailSyntax()) return;
    if (!validatePassword()) return;

    setLoading(true);
    agent.Accounts.sendOtp(voucherCode, email)
      .then((result) => {
        commonStore.setEmail(email.trim());
        commonStore.setPassword(password.trim());
        commonStore.setVoucher(voucherCode.trim());
        history.push("/verificationSent");
      })
      .catch((error) => {
        toast.error("The specified email is already in use, please select a different one");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateEmailSyntax = (): boolean => {
    if (email === "") return true;
    let regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regex.test(email)) {
      setValidEmail(true);
      return true;
    } else {
      toast.error("Invalid email format");
      setValidEmail(false);
      return false;
    }
  };

  const validatePassword = (): boolean => {
    setInValidPasswordConfirmation(false);
    if (password !== confirmPassword) {
      toast.error("Invalid password confirmation");
      setInValidPasswordConfirmation(true);
      return false;
    }

    //validate password length

    if (password == null || password.trim().length < 6) {
      setInValidPassword(true);
      toast.error("Password should contain at least 6 alphanumeric characters");
      return false;
    } else {
      setInValidPassword(false);
      return true;
    }
  };

  return (
    <>
    <Container className="progressbar-container">
    <ul className="progressbar">
      <li className="complete"></li>
      <li className="active"></li>
      <li></li>
    </ul>
  </Container>
      <Header as="h1">Create an account</Header>
      <Form className="globe-form">
        <Form.Field>
          <Label>Email</Label>
        <Input value={email} onChange={(e, d) => setEmail(e.target.value)} placeholder="Email address" />
        {!validEmail && <div id="divInvalidemailFormat" className="error-msg">Invalid email format</div>}
        </Form.Field>
        <Form.Field>
        <Label>Password</Label>
        <Input
          type="password"
          value={password}
          onChange={(e, d) => setPassword(e.target.value)}
          placeholder="Password"
        />
        </Form.Field>
        <Form.Field>
        <Label>Confirm Password</Label>
        <Input
          type="password"
          value={confirmPassword}
          onChange={(e, d) => setConfirmPassword(e.target.value)}
          placeholder="Confirm password"
        />
        {inValidPasswordConfirmation && <div className="error-msg">Invalid password confirmation</div>}
        {inValidPassword && <div className="error-msg">Password should contain at least 6 alphanumeric characters</div>}
        </Form.Field>
        <Checkbox
          checked={age}
          onChange={() => {
            setAge(!age);
          }}
          label="I am over 18"
        />
        <br />
        <Checkbox
          checked={terms}
          onChange={() => {
            setTerms(!terms);
          }}
          label={
            <label>
              I agree with the{" "}
              <a
                href="https://learningislands.com/terms-and-conditions/"
                target="_blank"
                rel="noreferrer"
              >
                Terms and conditions
              </a>{" "}
              and{" "}
              <a href="https://learningislands.com/privacy-policy/" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </label>
          }
        />

      <Button
        loading={loading}
        positive
        disabled={!age || !terms || email === "" || password === "" || confirmPassword === ""}
        onClick={() => {
          sendOTP();
        }}
      >
        Confirm
      </Button>
      </Form>
    </>
  );
}
