import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Input, Segment, Container } from "semantic-ui-react";
import agent from "../../api/agent";
import { useStore } from "../../stores/store";

export default function VerificationSent() {
  const history = useHistory();
  const [otp, setOtp] = useState<string>("");
  const [emptyOtp, setEmptyOtp] = useState<boolean>(false);
  const [invalidOtp, setInvalidOtp] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { commonStore } = useStore();
  const confirmOtp = () => {
    setEmptyOtp(false);
    setInvalidOtp(false);
    if (otp == null || otp.trim() === "") {
      setEmptyOtp(true);
      return;
    }

    if (commonStore.email == null || commonStore.password == null || commonStore.voucherCode == null)
      return;

    setLoading(true);
    agent.Accounts.register(commonStore.email, commonStore.password, otp, commonStore.voucherCode)
      .then((result) => {
        history.push("/confirmAccount");
      })
      .catch((error) => {
        console.log(error.detail);
        setInvalidOtp(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
    <Container className="progressbar-container">
    <ul className="progressbar">
      <li className="complete"></li>
      <li className="complete"></li>
      <li className="active"></li>
    </ul>
  </Container>
  <div className="globe-cont">
  <div className="globe-txt"><p>Check your email to verify your account</p></div>
      <div>
        <Input onChange={(e) => setOtp(e.target.value)} value={otp}></Input>
      </div>
      {emptyOtp && (
        <div id="divEmptyOTP" className="error-msg">
          <span>Please enter OTP code</span>
        </div>
      )}
      {invalidOtp && (
        <div id="divInvalidOTP" className="error-msg">
          <span>Invalid OTP Code</span>
        </div>
      )}
      <Button
        content="Confirm"
        positive
        loading={loading}
        onClick={() => {
          confirmOtp();
        }}
      ></Button>
      </div>
    </>
  );
}
