import axios, { AxiosResponse } from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL + "api/";
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string, signal?: { signal: AbortSignal }) => axios.get<T>(url, signal).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Vouchers = {
    validate: (voucherCode: string) => requests.get<boolean>(`Vouchers/${voucherCode}/validate`)
};

const Accounts = {
    sendOtp: (voucherCode: string, email: string) => requests.post<boolean>(`Accounts/sendotp`, {
        "voucherCode": voucherCode, "email": email
    }),
    register: (email: string, password: string, otp: string, voucherCode: string) => requests.post(`Accounts/register`, {
        "email": email,
        "password": password,
        "voucherCode": voucherCode,
        "otp": otp
    }),
    sendResetLink: (email: string) => requests.post("Accounts/sendresetpasswordlink", {
        "voucherCode": "", "email": email
    }),
    resetPassword: (email: string, password: string, otp: string) => requests.post(`Accounts/resetPassword`, {
        "email": email,
        "password": password,
        "voucherCode": "",
        "otp": otp
    }),
}

const agent = {
    Vouchers,
    Accounts
};

export default agent;