import React, { useState } from "react";
import { Icon, Header } from "semantic-ui-react";
import agent from "../../api/agent";
import { toast } from "react-toastify";

export default function ResetSuccessful() {
  return (
    <>
    <Header as="h1">Reset Password</Header>
    <Icon name="check circle" size="massive" color="green"></Icon>
      <div className="globe-txt"><p>Your password has been successfully changed.</p></div>
    </>
  );
}
