import React, { useState } from "react";
import { Button, Input, Label, Form, Header } from "semantic-ui-react";
import agent from "../../api/agent";
import { toast } from "react-toastify";

export default function SelectEmail() {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [mailSent, setMailSent] = useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

  const validateEmailSyntax = (): boolean => {
    if (email === "") return true;
    let regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regex.test(email)) {
      setInvalidEmail(false);
      return true;
    } else {
      toast.error("Invalid email format");
      setInvalidEmail(true);
      return false;
    }
  };

  const sendResetLink = () => {
    if (!validateEmailSyntax()) return;
    setLoading(true);
    agent.Accounts.sendResetLink(email)
      .then((result) => {
        setMailSent(true);
      })
      .catch(() => {
        toast.error("An error has occured while sending the password reset link. Please again later");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
    <Header as="h1">Reset Password</Header>
      <Form className="globe-form">
        <Form.Field>
    <Label>Email</Label>
      <Input value={email} onChange={(e, d) => setEmail(e.target.value)} placeholder="Email"></Input>
      {invalidEmail && <div className="error-msg">Invalid email address</div>}
      </Form.Field>
      <Button
        loading={loading}
        positive
        disabled={!email || email.trim() === ""}
        onClick={() => {
          sendResetLink();
        }}
      >
        Confirm
      </Button>
      {mailSent && <div className="error-msg">An email with the password reset link has been sent to your email address.</div>}
      </Form>
    </>
  );
}
